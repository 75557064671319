import { SplashScreen } from "@capacitor/splash-screen";
// import { StatusBar } from "@capacitor/status-bar";

import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";

SplashScreen.hide();

// StatusBar.setBackgroundColor({ color: "#fffdfb" }); // set to background color. change needed if dark theme implemented. not implemented on Web
// StatusBar.setOverlaysWebView({ overlay: true }); // don't use yet

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
